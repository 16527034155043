import React, { FC, createContext, useReducer, useEffect } from 'react';
import { IAuth } from './interfaces/IAuth';
import { INITIAL_STATE, AuthReducers } from './Auth.Reducers';
import { AuthEffects } from './Auth.Effects';
import { IAuthContext } from './interfaces/IAuthContext';
import { clearProfile, clearToken } from '../../utils/Auth.utils';

export const AuthContext = createContext<IAuthContext>(INITIAL_STATE);

const AuthProvider: FC<IAuth> = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducers, INITIAL_STATE);

    const effects = AuthEffects(dispatch);
    // const token = localStorage.getItem('token');

    // useEffect(() => {
    //     if (token) {

    //     } else {
    //         clearToken();
    //         clearProfile();
    //         window.location.reload();
    //     }
    // }, [token]);

    return <AuthContext.Provider value={{ ...state, effects }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
