import React, { FC } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

const Login = React.lazy(() => import('../../containers/login'));
const Register = React.lazy(() => import('../../containers/register'));
const ResetPassword = React.lazy(() => import('../../containers/resetPassword'));

export const PublicLayout: FC = () => {
    return (
        <React.Suspense fallback={<p>Loading...</p>}>
            <Switch>
                <Route path="/login" component={Login} />
                {/* <Route path="/register" component={Register} /> */}
                <Route path="/register-confirm/:id" component={Register} />
                <Route path="/forgot" component={ResetPassword} />
                <Redirect to="/login" />
            </Switch>
        </React.Suspense>
    );
};
