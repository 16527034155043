import {
    loginMutation,
    refreshTokenMutation,
    registerMutation,
    requestPasswordChangeMutation,
    verifyEmailMutation,
} from './Auth.GQL';
import { ApolloClient } from '../../../utils/Apollo-client.utils';
import { ILoginResponse } from '../interfaces/responses/ILoginResponse';
import { getRefresh } from '../../../utils/Auth.utils';
import { IRegisterRequest } from '../interfaces/request/IRegisterRequest';
import { IPasswordChange } from '../interfaces/request/IPasswordChange';
import { IVerifyEmail } from '../interfaces/request/IVerifyEmail';

export const login = async (email: string, password: string): Promise<ILoginResponse> => {
    const response: any = await ApolloClient().mutate({
        mutation: loginMutation,
        variables: {
            input: {
                password,
                email,
            },
        },
    });

    if (response.errors && response.errors[0]) {
        throw response.errors[0].message;
    }

    return response.data.Login;
};

export const register = async (payload: IRegisterRequest) => {
    const response = await ApolloClient().mutate({
        mutation: registerMutation,
        variables: {
            input: payload,
        },
    });

    if (response.errors && response.errors[0]) {
        throw response.errors[0];
    }

    return response.data.Register;
};

export const refreshToken = async (): Promise<ILoginResponse> => {
    const { data } = await ApolloClient().mutate({
        mutation: refreshTokenMutation,
        variables: {
            input: {
                jwtRefresh: getRefresh(),
            },
        },
    });

    return data.ExchangeRefreshToken;
};

export const passwordChange = async (payload: IPasswordChange) => {
    const response = await ApolloClient().mutate({
        mutation: requestPasswordChangeMutation,
        variables: {
            input: {
                email: payload.email,
            },
        },
    });

    if (response.errors && response.errors[0]) {
        throw response.errors[0];
    }

    return response.data.RequestPasswordChange;
};

export const verifyEmail = async (payload: IVerifyEmail) => {
    const response = await ApolloClient().mutate({
        mutation: verifyEmailMutation,
        variables: {
            input: payload,
        },
    });

    if (response.errors && response.errors[0]) {
        throw response.errors[0];
    }

    return response.data.VerifyEmail;
};
