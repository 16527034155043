import { Dispatch, useCallback } from 'react';
import { message } from 'antd';
import { login, passwordChange, register, verifyEmail } from './apollo/Auth.Service';
import { AuthActions, AuthActionUnion } from './Auth.Actions';
import { IAuthEffects } from './interfaces/IAuthEffects';
import { setAuthTokens, setMenuIndex, setProfile } from '../../utils/Auth.utils';
import { IRegisterRequest } from './interfaces/request/IRegisterRequest';
import { IPasswordChange } from './interfaces/request/IPasswordChange';
import { FormikHelpers, FormikValues } from 'formik';
import { IVerifyEmail } from './interfaces/request/IVerifyEmail';
import { history } from '../../App';
import { decodeToken } from 'react-jwt';

export type AuthEffectsType = (dispatch: Dispatch<AuthActionUnion>) => IAuthEffects;

export const AuthEffects: AuthEffectsType = (dispatch: Dispatch<AuthActionUnion>) => ({
    login: useCallback(async (email: string, password: string, helpers: FormikHelpers<FormikValues>) => {
        try {
            dispatch(AuthActions.setLoading(true));
            const response = await login(email, password);
            const userData: any = decodeToken(response.jwtBearer);
            if (userData.sub.role === 'admin') {
                response.username = userData.sub.email;
                dispatch(AuthActions.loginSuccess(response));
                setAuthTokens(response);
                setProfile(userData.sub.email);
                history.push('/');
            } else {
                message.error('User is not Admin');
                dispatch(AuthActions.loginError(['User is not Admin']));
            }
        } catch (err: any) {
            message.error(err);
            dispatch(AuthActions.loginError(err));
        } finally {
            dispatch(AuthActions.setLoading(false));
            helpers.setSubmitting(false);
        }
    }, []),

    register: useCallback(async (body: IRegisterRequest, helpers: FormikHelpers<FormikValues>) => {
        try {
            dispatch(AuthActions.setLoading(true));
            const response = await register(body);

            dispatch(AuthActions.registerSuccess(response));
            message.success(response.message);
            history.push(`/register-confirm/${body.email}`);
        } catch (err: any) {
            message.error(err.message);
            dispatch(AuthActions.registerError(err.message));
        } finally {
            dispatch(AuthActions.setLoading(false));
            helpers.setSubmitting(false);
        }
    }, []),

    passwordChange: useCallback(async (body: IPasswordChange, helpers: FormikHelpers<FormikValues>) => {
        try {
            dispatch(AuthActions.setLoading(true));
            const response = await passwordChange(body);

            dispatch(AuthActions.resetPasswordSuccess(response));
            message.success(response.message);
            history.push('/login');
        } catch (err: any) {
            message.error(err.message);
            dispatch(AuthActions.resetPasswordError(err.message));
        } finally {
            dispatch(AuthActions.setLoading(false));
            helpers.setSubmitting(false);
        }
    }, []),

    verifyEmail: useCallback(async (body: IVerifyEmail, helpers: FormikHelpers<FormikValues>) => {
        try {
            dispatch(AuthActions.setLoading(true));
            const response = await verifyEmail(body);

            message.success(response.message);
            history.push('/login');
        } catch (err: any) {
            message.error(err.message);
        } finally {
            dispatch(AuthActions.setLoading(false));
            helpers.setSubmitting(false);
        }
    }, []),

    // setMenu: useCallback((body: number) => {
    //     dispatch(AuthActions.setActiveMenu(body));
    //     setMenuIndex(body.toString());
    // }, []),
});
