import React, { FC } from 'react';
import { Switch, Route } from 'react-router-dom';
import Header2 from '../../components/header/header-new';

const StartHere = React.lazy(() => import('../../containers/start-here'));
const Products = React.lazy(() => import('../../containers/products'));
const Discounts = React.lazy(() => import('../../containers/discounts'));
const Meditations = React.lazy(() => import('../../containers/meditations'));
const MeditationsDetail = React.lazy(() => import('../../containers/meditations-detail'));
const Yoga = React.lazy(() => import('../../containers/yoga'));
const YogaDetail = React.lazy(() => import('../../containers/yoga-detail'));
const Users = React.lazy(() => import('../../containers/users'));
const Home = React.lazy(() => import('../../containers/home'));
const Authors = React.lazy(() => import('../../containers/authors'));
const MeditationCategories = React.lazy(() => import('../../containers/categories'));
const YogaCategories = React.lazy(() => import('../../containers/yoga-categories'));
const Courses = React.lazy(() => import('../../containers/courses'));
const CoursesModules = React.lazy(() => import('../../containers/courses-module'));
const ModulesLessons = React.lazy(() => import('../../containers/courses-modules-lessons'));

export const PrivateLayout: FC = () => {
    return (
        <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">
            <Header2 />
            <React.Suspense fallback={<p>Loading...</p>}>
                <Switch>
                    <Route path="/home" component={Home} />
                    <Route path="/start-here" component={StartHere} />
                    <Route path="/users" component={Users} />
                    <Route path="/authors" component={Authors} />
                    <Route path="/meditations/:id" component={MeditationsDetail} />
                    <Route path="/meditations" component={Meditations} />
                    <Route path="/yoga/:id" component={YogaDetail} />
                    <Route path="/yoga" component={Yoga} />
                    <Route exact path="/courses" component={Courses} />
                    <Route exact path="/courses/module/:name/:id" component={CoursesModules} />
                    <Route exact path="/courses/module/lesson/:idCurso/:idModulo/:name" component={ModulesLessons} />
                    <Route path="/meditation-categories" component={MeditationCategories} />
                    <Route path="/yoga-categories" component={YogaCategories} />
                    <Route path="/discounts" component={Discounts} />
                    <Route exact path="/" component={Home} />
                    <Route exact path="/products" component={Products} />
                </Switch>
            </React.Suspense>
        </div>
    );
};
