import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import 'antd/dist/antd.css';
import './rsc/css/_variables.scss';
import './index.css';
import AuthProvider from './context/auth/Auth.Provider';

ReactDOM.render(
    <AuthProvider>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </AuthProvider>,
    document.getElementById('root')
);
